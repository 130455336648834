'use strict'

const _ = require('lodash')
const window = require('./windowGlobal')
const stringifySpecialTypesReplacer = require('./stringifySpecialTypesReplacer')

const WIX_CODE_INTENT = 'WIX_CODE'
const WIX_CODE_CONSOLE_MESSAGE_TYPE = 'console'

function isPreview() {
    return window.parent && window.parent !== window
}

function isWixCodeConsoleMessage(msg) {
    return msg.intent === WIX_CODE_INTENT && msg.type === WIX_CODE_CONSOLE_MESSAGE_TYPE
}

function convertToWixCodeConsoleMessage(logLevel, ...msg) {
    return {
        intent: WIX_CODE_INTENT,
        type: WIX_CODE_CONSOLE_MESSAGE_TYPE,
        data: {
            logLevel,
            args: [...msg]
        }
    }
}

function logWixCodeConsoleMessage(msg) {
    if (!msg) {
        return
    }

    if (_.isString(msg)) {
        msg = convertToWixCodeConsoleMessage('INFO', msg)
    }

    if (isWixCodeConsoleMessage(msg) && isPreview()) {
        window.parent.postMessage(serializeMessage(msg), '*')
    }
}

function serializeMessage(message) {
    return JSON.stringify(message, stringifySpecialTypesReplacer)
}

function logWixCodeConsoleError(error) {
    if (isPreview()) {
        window.parent.postMessage(
            serializeMessage(
                convertToWixCodeConsoleMessage('ERROR', error.name, error.message, error.stack)), '*')
    }
}

module.exports = {
    logWixCodeConsoleMessage,
    logWixCodeConsoleError
}
